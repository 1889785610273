import React, { Component } from "react";
import Helmet from "react-helmet";
import config from "../../data/SiteConfig";

import Layout from "../components/layout"
import SectionTitle from "../components/SectionTitle/SectionTitle";
import Content from "../components/Content/Content";

import FullWidthImage2  from "../components/FullWidthImage2/FullWidthImage2";
// import img from "../imgs/american-public-power-association-419672-unsplash.jpg";
const img = 'https://res.cloudinary.com/downtowndev/image/upload/q_auto,f_auto/american-public-power-association-419672-unsplash_c7cpaa.jpg';

class AboutPage extends Component {
  render() {
    return (
      <Layout location={this.props.location}>
      <div className="about-container underNavbar">
        <Helmet htmlAttributes={{ lang: 'en' }} title={`Privacy Policy | ${config.siteTitle}`} />
        <FullWidthImage2 title={'Privacy Policy'} image={img} />
          <Content className='page-content'>
            <p>Sverdrup Engineering Services has created this privacy statement in order to demonstrate our firm commitment to privacy. The following discloses our information gathering and dissemination practices for this website: Sverdrup Engineering Services.</p>
            <p>We use your IP address to help diagnose problems with our server, and to administer our website.</p>
            <p>This site contains links to other sites. Sverdrup Engineering Services is not responsible for the privacy practices or the content of such websites.</p>
            {/* <p><strong>Public Forums</strong></p>
            <p>This site may make chat rooms, forums, message boards, and/or news groups available to its users. Please remember that any information that is disclosed in these areas becomes public information and you should exercise caution when deciding to disclose your personal information.</p>
            */}
            <p><strong>Contacting the Website</strong></p>
            <p>If you have any questions about this privacy statement, the practices of this site, or your dealings with this website, feel free to contact us.</p>
          </Content>
      </div>
      </Layout>
    );
  }
}

export default AboutPage;
